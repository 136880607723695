import { useState, type FC, type FormEvent, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import Message, { MessageType } from '../../Message/Message';
import PasswordInput from '../../../../../general/PasswordInput/PasswordInput';
import ButtonStack from '../../../../../general/Button/ButtonStack';
import Button from '../../../../../general/Button/Button';
import PasswordStrengthIndicator from '../../PasswordStrengthIndicator/PasswordStrengthIndicator';
import { PublicRoute } from '../../../Navigation/AuthenticationRoutes';
import { UserLookupContext } from '../../../Provider/UserLookupProvider/UserLookupProvider';
import Input from '../../../../../general/Input/Input';
import styles from './ResetPassword.module.scss';
import useTranslate from '../../../../../general/Translation/hooks/UseTranslate';
import { AuthFlowStateContext } from '../../../Provider/AuthFlowStateProvider/AuthFlowStateContext';
import type { FetchResponseNotOkError } from '../../../../../../js/api/FetchResponseNotOkError';
import type { PasswordResetMutationArgs, PasswordResetResponse } from '../../../Query/PasswordResetMutation';
import passwordResetMutation from '../../../Query/PasswordResetMutation';

const InvalidPasswordResetToken: FC = () => {
    const translate = useTranslate();

    return (
        <div>
            <h2 className="mb-8">{translate('auth', 'set_new_password_title')}</h2>
            <Message type={MessageType.Error} message={translate('auth', 'reset_token_invalid')}/>
        </div>
    );
};

const ResetPassword: FC = () => {
    const navigate = useNavigate();
    const { setUserLookup } = useContext(UserLookupContext);
    const { resetTokenValidationResult } = useContext(AuthFlowStateContext);
    const translate = useTranslate();

    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const parseError = (error: FetchResponseNotOkError) => {
        error.response.json()
            .then((data: { message: string }) => setErrorMessage(data.message || translate('auth', 'generic_error_message')))
            .catch(() => setErrorMessage(translate('auth', 'generic_error_message')));
    };

    const {
        isError: isPasswordResetErroneous,
        isPending: isResettingPassword,
        mutate: resetPassword,
    } = useMutation<PasswordResetResponse, FetchResponseNotOkError, PasswordResetMutationArgs>({
        ...passwordResetMutation(),
        onError: parseError,
        onSuccess: () => {
            // Since we're going to redirect to the email login, ensure the correct email address is already set.
            setUserLookup({
                confirmationPending: false,
                email: resetTokenValidationResult?.email ?? '',
                exists: true,
            });

            const message = {
                content: translate('auth', 'password_reset_success'),
                type: MessageType.Success,
            };

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate(
                PublicRoute.EMAIL_LOGIN,
                { state: { message } },
            );
        },
    });

    const [password, setPassword] = useState('');
    const isPasswordTooShort = password.length < 8;

    const onResetPassword = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isPasswordTooShort) {
            return;
        }

        resetPassword({ password, token: resetTokenValidationResult?.token ?? '' });
    };

    if (!resetTokenValidationResult || !resetTokenValidationResult.valid) {
        return <InvalidPasswordResetToken />;
    }

    return (
        <form onSubmit={onResetPassword}>
            <div>
                <h2 className="mb-6">{translate('auth', 'set_new_password_title')}</h2>

                {isPasswordResetErroneous && <Message type={MessageType.Error} message={errorMessage ?? ''}/>}

                <Input
                    type="text"
                    label={translate('auth', 'email_label')}
                    value={resetTokenValidationResult.email}
                    className={styles.email}
                />

                <PasswordInput
                    required
                    label={translate('auth', 'new_password_label')}
                    password={password}
                    onChange={setPassword}
                    className="mt-4"
                />

                <PasswordStrengthIndicator className="mt-6" password={password}/>

                <ButtonStack isFluid className="mt-6">
                    <Button type="submit" isPrimary isLoading={isResettingPassword} disabled={isResettingPassword || isPasswordTooShort}>
                        {translate('auth', 'save_password_button')}
                    </Button>
                </ButtonStack>
            </div>
        </form>
    );
};

export default ResetPassword;
